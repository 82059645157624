<!-- template for the modal component -->
<template>
  <transition name="modal">
    <div class="modal-mask" @click="CLICK($event)">
      <div class="modal-wrapper">
        <div
          class="modal-container"
          :class="{ 'modal-container-noframe': noframe }"
        >
          <div class="modal-header">
            <slot name="header">
              <img
                class="closemodal"
                src="@/assets/img/ico_close.svg"
                alt
                @click="
                  $emit('close');
                  closeModal();
                "
              />
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">Default Body</slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <div class="modalfooter">
                <div class="infologo">
                  <img
                    src="@/assets/img/TCTB_Logo.svg"
                    alt="Ticaret Bakanlığı logo"
                  />
                  <img src="@/assets/img/Tobb_Logo.svg" alt="TOBB logo" />
                </div>
                <div class="text">
                  <p>
                    İleti Yönetim Sistemi,
                    <b>Ticaret Bakanlığı</b> tarafından görevlendirilmiş olan
                    <br />
                    <b>Türkiye Odalar ve Borsalar Birliği</b>'nin sağladığı
                    yasal bir platformdur.
                  </p>
                </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    noframe: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    document.addEventListener("keyup", this.KEYUP);
    this.openModal();
  },

  destroyed() {
    this.closeModal();
    document.removeEventListener("keyup", this.KEYUP);
  },

  computed: {
    ...mapState("app", ["modalName", "lastOpenedModalName"])
  },

  watch: {
    $route(to, from) {
      this.closeModal();
      document.removeEventListener("keyup", this.KEYUP);
    }
  },

  methods: {
    ...mapMutations("app", ["CLOSE_MODAL"]),
    KEYUP(e) {
      if (e.key == "Escape") {
        this.$emit("close");
        this.CLOSE_MODAL();
      }
    },
    CLICK(e) {
      if (
        e.target.className === "modal-mask" ||
        e.target.className === "modal-wrapper"
      ) {
        this.$emit("close");
        this.CLOSE_MODAL();
      }
    },
    openModal(modal) {
      // Note: fixed elements will also need the margin
      // adjustment (like a fixed header, if you have one).
      const scrollBarWidth = window.innerWidth - document.body.offsetWidth;
      document.body.style.margin = `0px ${scrollBarWidth}px 0px 0px`;
      document.body.style.overflow = "hidden";
    },

    closeModal(modal) {
      document.body.style.margin = "";
      document.body.style.overflow = "";

      this.$env.CONSOLE.log("MODAL TO CLOSE:", this.modalName);
      this.$env.CONSOLE.log("lastOpenedModalName:", this.lastOpenedModalName);

      if (this.lastOpenedModalName === "ModalAydinlatmaKurumsal" && !this.modalName) {
        window.open("http://hs.iys.org.tr/", "_blank");
      }

      if (this.lastOpenedModalName === "ModalAydinlatmaVatandas" && !this.modalName) {
        window.open("http://vatandas.iys.org.tr/", "_blank");
      }

      this.CLOSE_MODAL();
    }
  }
};
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px) saturate(2.4);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  max-height: 100vh;
  max-width: 100vw;
  overflow-y: auto;
  margin: 0 auto;
}

.modal-container {
  width: 75%;
  max-width: 1240px;
  margin: 2vw auto;
  padding: 3vw;
  background-color: #fff;
  border-radius: 2vw;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  position: relative;
}

.modal-container-noframe {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: none;
}

.modal-header .closemodal {
  width: 2.5vw;
  position: absolute;
  right: 1.5vw;
  top: 1.5vw;
  background: #eff2f7;
  cursor: pointer;
  border-radius: 0.5vw;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/*  on big screens ********************************************************************************************/
@media only screen and (min-height: 1000px) {
  .modal-header .closemodal {
    width: 1.5vw;
  }
}

/*  on phones ********************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .modal-container {
    width: 96% !important;
  }
  .modal-header .closemodal {
    width: 8vw;
    border-radius: 2vw;
  }
}

/*  on tablets portrait ********************************************************************************************/

@media (orientation: portrait) and (min-width: 501px) {
  .modal-header .closemodal {
    width: 5vw;
    border-radius: 0.5vw;
  }
  .modal-container {
    width: 96% !important;
  }
}

/*  on 16:9 resolutions ***************************************************************************************************************************************************/

@media screen and (max-aspect-ratio: 25/9) and (min-aspect-ratio: 18/9) {
  .modal-header .closemodal {
    width: 1.5vw;
  }
}
</style>
