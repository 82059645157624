<template>
  <modal-base>
    <template v-slot:body>
      <div class="">
        <!-- <h2 class="center mb"  v-html="modalOptions.item.desc.join(' ')"></h2> -->
        <!-- <div class="video_wrapper"> -->
          <img :src="modalOptions.src" alt="" />
        <!-- </div> -->
      </div>
    </template>
    <template v-slot:footer>
      <div></div>
    </template>
  </modal-base>
</template>

<script>
import { mapState } from "vuex";
import ModalBase from "./ModalBase";
import CustomError from "../../misc/errors";

export default {
  components: {
    ModalBase,
  },

  data() {
    return {
      show_notify: false,

      requester: -1,

      fullName: "",

      email: {
        str: "",
        validated: false,
      },

      captcha: {
        captchaId: "",
        captchaCode: "",
      },

      problem: {
        fullName: "",
        email: "",
        captchaCode: "",
      },
    };
  },

  computed: {
    ...mapState("app", ["notifyRequesterType", "modalOptions"]),
    next() {
      return (
        this.requester > -1 &&
        this.email.validated &&
        this.captcha.captchaCode.length > 1
      );
    },
  },

  mounted() {
    this.requester = this.notifyRequesterType;
    if (this.$env.MOCK) {
      this.fullName = "Ali Muhlis";
      this.email = {
        str: "deneme@cicekcilik.com.tr",
        validated: true,
      };
    }
  },

  methods: {
    GOTO_SSS() {
      this.$root.$emit("close_modal");
      if (this.$router.currentRoute.path !== "/iys/sss")
        this.$router.push("/iys/sss");
    },

    async NOTIFY() {
      // if (this.requester < 0) {
      //   await this.Alert("Lütfen kategori seçimi yapınız.");
      //   return;
      // }
      try {
        const res = await this.$store.dispatch("app/notify_me", {
          requester: 0, // 0: Vatandas 1: HS
          fullName: this.fullName,
          email: this.email.str,
          ...this.captcha,
        });

        if (res.ok) {
          // _paq.push([
          //   "trackEvent",
          //   "Beni_Haberdar_Et",
          //   "Vatandas_Kayit",
          //   "Success",
          // ]);
          await this.Alert(
            `Kaydınız başarılı şekilde oluşturuldu. İleti Yönetim Sistemi kullanıma açıldığında ${this.email.str} adresi üzerinden bilgilendirileceksiniz.`,
            "success"
          );

          this.$root.$emit("close_modal");
        }
      } catch (cerror) {
        // _paq.push([
        //   "trackEvent",
        //   "Beni_Haberdar_Et",
        //   "Vatandas_Kayit",
        //   "Error",
        // ]);
        this.HandleValidationError(cerror);
      }
    },
  },
};
</script>

<style scoped>
.video_wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*  on phones ********************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
}

/*  on tablets portrait ********************************************************************************************/

@media (orientation: portrait) and (min-width: 501px) {
}
</style>
