<template>
  <modal-base>
    <template v-slot:header> </template>

    <template v-slot:body>
      <div class="modalcontent">

        <div>
          <h3 class="blue center mb">Hizmet Sağlayıcılar</h3>
          <div class="formline">

            <h4>
              İleti Yönetim Sistemi;
              <br />Yakında hizmete başlayacaktır.
            </h4>
            <p>
              <tool-tip-hs></tool-tip-hs>lar için başvuru süreci başlamıştır.
              <!-- Lütfen <router-link to="/hizmet-saglayicilar" class="blue">detaylı bilgi</router-link> sayfasını ziyaret ediniz. -->
            </p>
            <div class="">
              <div class="btn main">
                <router-link to="/hizmet-saglayici/basvuru/yeni-giris">
                  <div>
                    <span class="bigtxt">Yeni Başvuru</span>
                    <!-- <br /><span class="light">(Hizmet Sağlayıcılar için)</span> -->
                  </div>
                </router-link>
              </div>

              <p>
                Daha önce başladığınız bir başvuruya devam etmek ya da tamamladığınız başvurunuzun
                durumunu sorgulamak için aşağıdaki bağlantıyı kullanınız.
              </p>

              <div class="btn main second">
                <router-link to="/hizmet-saglayici/basvuru/devam-giris">
                  <div>
                    <span class="bigtxt">Mevcut Başvuru</span><br />
                    <!-- <span class="light">(DEVAM ET veya DURUM SORGULA)</span> -->
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>




        <div>
          <h3 class="blue center mb">Vatandaşlar</h3>
          <div class="formline">

            <h4>İleti Yönetim Sistemi; <br />Vatandaşların hizmetine daha sonra girecektir.</h4>
            <p>
              1 Haziran 2020 tarihinden itibaren vatandaşlarımız da bu hizmeti kullanabilecektir.
              Lütfen
              <router-link to="/vatandaslar" class="blue">detaylı bilgi</router-link>
              sayfasını ziyaret ediniz.
            </p>

            <div v-if="!show_notify" class="formpart front">
              <form>
                <p>
                  İleti Yönetim Sistemi Vatandaşların kullanımına açıldığında ilk siz haber almak
                  ister misiniz?
                </p>
                <div class="btn main second">
                  <a @click="show_notify = true">Haber Almak İstiyorum</a>
                </div>
              </form>
            </div>

            <div v-if="show_notify" class="formpart">
              <form>
                <p>
                  İleti Yönetim Sistemi'nin, vatandaşlarımızın kullanımına açılması hakkında bilgi
                  almak için aşağıdaki kutucuğa e-posta adresinizi yazabilir ve bildirimleri takip
                  edebilirsiniz.
                </p>

                <div :class="{ notvalid: problem.fullName }">
                  <label>Ad Soyad:</label>
                  <input type="text" name="#" v-model="fullName" />
                  <div class="hint">{{ problem.fullName }}</div>
                </div>

                <email v-model="email" :message="problem.email"></email>

                <capcha
                  v-model="captcha"
                  section="NOTIFY-ME"
                  :message="problem.captchaCode"
                  @input="problem.captchaCode = ''"
                ></capcha>

                <div class="btn main" :class="{ dsbl: !next }">
                  <a @click="NOTIFY">
                    <div>Gönder</div>
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>




      </div>
    </template>
    <template v-slot:footer>
      <div></div>
    </template>
  </modal-base>
</template>

<script>
import ModalBase from "./ModalBase";
import CustomError from "../../misc/errors";

export default {
  components: {
    ModalBase
  },

  data() {
    return {
      show_notify: false,

      requester: 1,

      fullName: "",

      email: {
        str: "",
        validated: false
      },

      captcha: {
        captchaId: "",
        captchaCode: ""
      },

      problem: {
        fullName: "",
        email: "",
        captchaCode: ""
      }
    };
  },

  computed: {
    next() {
      return (
        this.fullName.length > 0 && this.email.validated && this.captcha.captchaCode.length > 0
      );
    }
  },

  mounted() {
    if (this.$env.MOCK) {
      this.fullName = "Ali Muhlis";
      this.email = {
        str: "deneme@cicekcilik.com.tr",
        validated: true
      };
    }
  },

  methods: {
    GOTO_SSS() {
      this.$root.$emit("close_modal");
      if (this.$router.currentRoute.path !== "/iys/sss") this.$router.push("/iys/sss");
    },

    async NOTIFY() {
      this.clearValidationErrorDisplays();
      try {
        const response = await this.$store.dispatch("app/notify_me", {
          requester: this.requester, //0: Vatandas 1: HS
          fullName: this.fullName,
          email: this.email.str,
          ...this.captcha
        });

        if (response.ok) {
          await this.Alert("E-posta adresiniz başarılı bir şekilde alındı.", "success");
          this.$root.$emit("close_modal");
        }
      } catch (cerror) {
        this.HandleValidationError(cerror);
      }
    }
  }
};
</script>

<style scoped>


.half {
  width: 50% !important;
}

.half p {
  padding-right: 1vw;
}

.izinsection .radio-group {
  position: relative;
  width: 100%;
  display: flex;
  flex-flow: wrap;
  margin-bottom: 2vw;
}

.izinsection .radio-group input {
  position: relative;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.izinsection .radio-group input[type="radio"] {
  position: absolute;
  visibility: hidden;
  display: none;
}

.izinsection .detail {
  display: flex;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.izinsection input[type="radio"] + label {
  border-radius: 0.5vw;
  width: 49% !important;
  background: #eee;
  border: solid 2px #eee;
  padding: 0.4vw 0.4vw;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.izinsection label {
  color: #55565e;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-weight: bold;
  padding: 5px 20px;
}

.izinsection input[type="radio"]:checked + label {
  border: solid 2px #4da0de;
}

/*  on phones ********************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .izinsection input[type="radio"] + label {
    border-radius: 2vw;
    padding: 2vw;
  }
  .modalcontent > div {
    flex-wrap: wrap; padding: 6vw;
  }
  .modalcontent > div > .half {
    width: 100% !important;
  }
  .izinsection .detail {
    padding: 2vw 0 5vw 0;
  }
}

/*  on tablets portrait ********************************************************************************************/

@media (orientation: portrait) and (min-width: 501px) {
}
</style>
