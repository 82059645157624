<template>
  <modal-base-cumhuriyet>
    <template v-slot:body>
      <div class="cumhuriyet-banner-container">
        <div class="cumhuriyet-banner">
          <router-link to="/">
            <img
              class="cumhuriyet-img"
              src="@/assets/img/cumhuriyet-banner-v1.jpg"
              alt="İleti Yönetim Sistemi - 100.yıl - Atatürk"
            />
          </router-link>
        </div>

        <div class="iys-logo-wrapper">
          <div class="logo">
            <router-link to="/">
              <img
                src="@/assets/img/iys-logo.svg"
                alt="İleti Yönetim Sistemi - İleti Yönetim Sistemi - Logo"
              />
            </router-link>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div></div>
    </template>
  </modal-base-cumhuriyet>
</template>

<script>
import { mapState } from "vuex";
import ModalBaseCumhuriyet from "./ModalBaseCumhuriyet";
import CustomError from "../../misc/errors";

export default {
  components: {
    ModalBaseCumhuriyet,
  },

  data() {
    return {
      show_notify: false,

      requester: -1,

      fullName: "",

      email: {
        str: "",
        validated: false,
      },

      captcha: {
        captchaId: "",
        captchaCode: "",
      },

      problem: {
        fullName: "",
        email: "",
        captchaCode: "",
      },
    };
  },

  computed: {
    ...mapState("app", ["notifyRequesterType"]),
    next() {
      return (
        this.requester > -1 &&
        this.email.validated &&
        this.captcha.captchaCode.length > 1
      );
    },
  },

  mounted() {
    this.requester = this.notifyRequesterType;
    if (this.$env.MOCK) {
      this.fullName = "Ali Muhlis";
      this.email = {
        str: "deneme@cicekcilik.com.tr",
        validated: true,
      };
    }
  },

  methods: {
    GOTO_SSS() {
      this.$root.$emit("close_modal");
      if (this.$router.currentRoute.path !== "/iys/sss")
        this.$router.push("/iys/sss");
    },

    async NOTIFY() {
      // if (this.requester < 0) {
      //   await this.Alert("Lütfen kategori seçimi yapınız.");
      //   return;
      // }
      try {
        const res = await this.$store.dispatch("app/notify_me", {
          requester: 0, // 0: Vatandas 1: HS
          fullName: this.fullName,
          email: this.email.str,
          ...this.captcha,
        });

        if (res.ok) {
          // _paq.push(["trackEvent", "Beni_Haberdar_Et", "Vatandas_Kayit", "Success"]);
          await this.Alert(
            `Kaydınız başarılı şekilde oluşturuldu. İleti Yönetim Sistemi kullanıma açıldığında ${this.email.str} adresi üzerinden bilgilendirileceksiniz.`,
            "success"
          );

          this.$root.$emit("close_modal");
        }
      } catch (cerror) {
        // _paq.push(["trackEvent", "Beni_Haberdar_Et", "Vatandas_Kayit", "Error"]);
        this.HandleValidationError(cerror);
      }
    },
  },
};
</script>

<style scoped>
.cumhuriyet-banner-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.iys-logo-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  padding-bottom: 1vh;
}
.cumhuriyet-banner {
  padding-left: 0px;
  padding-top: -10px;
  border-radius: 20px;
  text-align: center;
}

.cumhuriyet-img {
  border-radius: 20px;
  width: 100%;
  padding-top: 0;
}

.video_wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9, for an aspect ratio of 1:1 change to this value to 100% */
}

iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/*  on phones ********************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {
  .iys-logo-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 0px;
  }
  .cumhuriyet-banner {
    padding-left: 0px;
    padding-top: 0px;
    width: 100%;
    height: 100%;
  }
}

/*  on tablets portrait ********************************************************************************************/

@media  (min-width: 1500px) {
  .cumhuriyet-img {
    width: 80%;
  }
}
</style>
