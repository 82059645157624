<template>
  <modal-base>
    <template v-slot:body>
      <div class="modalcontent">
        <div class="wide marginat duyuru">
          <h3 class="blue">DUYURU</h3>

            <!-- <p>Artık İleti Yönetim Sistemi’ni kullanmak daha kolay!</p> -->
            <p><b>1 Aralık 2020</b> tarihine kadar başvurusunu tamamlayan <b>75.000</b>'e kadar onaylı adres sayısına sahip tüm hizmet sağlayıcılara Seviye-1 hizmetleri bir yıl süreyle <b class="blue">ücretsiz!</b>


                <!-- <b>1 Aralık 2020</b> tarihine kadar başvurusunu tamamlayan tüm
                hizmet sağlayıcılara <b>İLETİ 5</b>, <b>İLETİ 25</b> ve
                <b>İLETİ 75</b>, Seviye - 1 hizmetleri bir yıl süreyle <b class="blue">ücretsiz.</b> -->
            </p>

                    <div class="btn half right">
            <!-- <router-link to="/iletisim" class="flex">Bize ulaşın <img class="go" src="@/assets/img/ico_detail.svg" alt="" /></router-link> -->
            <router-link to="/hizmet-saglayici/paketler-ve-moduller"
              >Detaylı bilgi</router-link
            >
          </div>
        </div>



      </div>
    </template>
    <template v-slot:footer>
      <div></div>
    </template>
  </modal-base>
</template>

<script>
import { mapState } from "vuex";
import ModalBase from "./ModalBase";
import CustomError from "../../misc/errors";

export default {
  components: {
    ModalBase
  },

  data() {
    return {
      show_notify: false,

      requester: -1,

      fullName: "",

      email: {
        str: "",
        validated: false
      },

      captcha: {
        captchaId: "",
        captchaCode: ""
      },

      problem: {
        fullName: "",
        email: "",
        captchaCode: ""
      }
    };
  },

  computed: {
    ...mapState("app", ["notifyRequesterType"]),
    next() {
      return (
        this.requester > -1 &&
        this.email.validated &&
        this.captcha.captchaCode.length > 1
      );
    }
  },

  mounted() {
    this.requester = this.notifyRequesterType;
    if (this.$env.MOCK) {
      this.fullName = "Ali Muhlis";
      this.email = {
        str: "deneme@cicekcilik.com.tr",
        validated: true
      };
    }
  },

  methods: {
    GOTO_SSS() {
      this.$root.$emit("close_modal");
      if (this.$router.currentRoute.path !== "/iys/sss")
        this.$router.push("/iys/sss");
    },

    async NOTIFY() {
      // if (this.requester < 0) {
      //   await this.Alert("Lütfen kategori seçimi yapınız.");
      //   return;
      // }
      try {
        const res = await this.$store.dispatch("app/notify_me", {
          requester: 0, // 0: Vatandas 1: HS
          fullName: this.fullName,
          email: this.email.str,
          ...this.captcha
        });

        if (res.ok) {
          // _paq.push(["trackEvent", "Beni_Haberdar_Et", "Vatandas_Kayit", "Success"]);
          await this.Alert(
            `Kaydınız başarılı şekilde oluşturuldu. İleti Yönetim Sistemi kullanıma açıldığında ${this.email.str} adresi üzerinden bilgilendirileceksiniz.`,
            "success"
          );

          this.$root.$emit("close_modal");
        }
      } catch (cerror) {
        // _paq.push(["trackEvent", "Beni_Haberdar_Et", "Vatandas_Kayit", "Error"]);
        this.HandleValidationError(cerror);
      }
    }
  }
};
</script>

<style scoped>


.modalcontent > div {
  width: 100%;
  padding: 0;
  margin: 0 auto;
}

.half {
  width: 50% !important;
}
.wide {
  width: 70% !important;
}
.half p {
  padding-right: 1vw;
}

.duyuru h3 {font-size: 1.7rem !important;}
.duyuru p , .duyuru p  *  {
    font-size: 1.30rem !important;
    line-height: 1.5;
}

.duyuru p    {
   margin: 10px 0 20px 0;
}

.right { margin: 50px 0 0 auto;}

/*  on phones ********************************************************************************************/

@media screen and (max-aspect-ratio: 13/9) and (max-width: 500px) {


}

/*  on tablets portrait ********************************************************************************************/

@media (orientation: portrait) and (min-width: 501px) {


}
</style>
